import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [ 
        //首页
        {
            path: '/',
            name:'/',
            component: () => import("@/pages/Home/HomeIndex.vue"),
        },
        //商品列表
        {
            path: '/products',
            name:'/products',
            component: () => import("@/pages/Home/productsIndex.vue")
        },
        //
        {
            path: '/support',
            name:'/support',
            component: () => import("@/pages/Home/SupportIndex.vue")
        },
        //
        {
            path: '/about',
            name:'/about',
            component: () => import("@/pages/Home/AboutIndex.vue")
        },
        //
        {
            path: '/app',
            name:'/app',
            component: () => import("@/pages/Home/AppIndex.vue")
        },
        //玩法
        {
            path: '/playing',
            name:'/playing',
            component: () => import("@/pages/Home/playingIndex.vue")
        },
        //商品详情
        {
            path: '/detail',
            name:'/detail',
            component: () => import("@/pages/Home/ProductsDetail.vue")
        },
        {
            path: '/conformity',
            name:'/conformity',
            component: () => import("@/pages/Home/ConformityIndex.vue")
        },
        //介绍
        {
            path: '/referral',
            name:'/referral',
            component: () => import("@/pages/Referral/ReferralIndex.vue")
        },
        {
            path: '/terms',
            name:'/terms',
            component: () => import("@/pages/Referral/termsIndex.vue")
        },{
            path: '/legal',
            name:'/legal',
            component: () => import("@/pages/Referral/legalIndex.vue")
        }
    ]
})



export default router;