module.exports = {
	//home
	// {{ $t('home.') }}
	home: {
		title:'Explore More',
		appTitle:'Free App',
		appText:'Building instructions, remote control, and programming all in one App!',
		appName:'Android without Google Play?',
		name:'Learn more',
		Buy:'Buy',
		titleText:'Explore More',
		Coming:'Coming Soon',
		Features:'Features',
		NEW:'NEW',
		THE:'THE NEW'
	},
	// {{ $t('products.') }}
	products:{
		title:'All Apitor products.',
		text:'Find out which kit is geared for you!'
	},
	// {{ $t('app.') }}
	app:{
		title:'Step-by-step Guide',
	},
	// {{ $t('support.') }}
	support:{
		title:'What can we help you with? Contact Apitor!',
		Download:'Download',
		emailName:'Contact Us for Support',
		robot:'I’m not a robot',
		name:'Name',
		Email:'Email',
		conter:'Your message…',
		Submit:'Submit',
		code:'Enter the authentication code',
		cancel:'cancel',
		verify:'Verification',
	},
	// {{ $t('about.') }}
	about:{
		text:'Contact us',
		name:'Why Partner with Us',
		title:'Contact Us for Support'
	},
	// {{ $t('referral.') }}
	referral:{
		title:'Privacy Policy',
		title2:'Apitor Terms of Use',
		title3:'Apitor Legal Terms',
	},
	// {{ $t('footer.') }}
	footer:{
		title:'Copyright © 2022 Apitor Technology Co., Ltd. All rights reserved.',
		title2:'Privacy Policy',
		title3:'Terms of Use',
		title4:'Legal Terms',
		Follow:'FOLLOW US',
		name:'PRODUCTS',
		name2:'APP',
		name3:'SUPPORT',
		name4:'ABOUT',
		name5:'HOME',
		text:'App Store',
		text2:'Google Play',
		text3:'Building instructions',
		text4:'Manual',
		text5:'EC declaration of conformity',
		text6:'Contact Us',
		nav:'English',
		nav2:'日本語',
	},
	// {{ $t('detail.') }}
	detail:{
		Ages:'Ages',
		Pieces:'Pieces',
		Model:'Model',
		ControlModule:'Control Module',
		Motor:'Motors',
		Senors:'Senors',
		lights:'LED lights',
		cm:'cm',
		Download:'Download App',
	},
	// {{ $t('contact.') }}
	contact:{
		name:'Thank You！Your message has been sent! We will reply back to your enquiry as soon as possible.',
		text:'Error code input error',
		text1:'Illegal email address',
		text2:'Name cannot be empty',
		text3:'Email address cannot be empty',
		text4:'Information cannot be empty'
	}
}