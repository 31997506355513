module.exports = {
	// {{ $t('home.') }}
	home: {
		title:'探索の詳細',
		appTitle:'無料アプリ',
		appText:'組み立て説明書、リモコン、プログラミングが1つのアプリに！',
		appName:'Google PlayのAndroidはありませんか？',
		name:'詳しい情報',
		Buy:'購入する',
		titleText:'探索の詳細',
		Coming:'すぐ参ります',
		Features:'特徴',
		NEW:'新規',
		THE:'新発売'
	},
	// {{ $t('products.') }}
	products:{
		title:'商品一覧。',
		text:'お気に入りのロボットキットを探そう！'
	},
	// {{ $t('app.') }}
	app:{
		title:'Step-by-step ガイド',
	},
	// {{ $t('support.') }}
	support:{
		title:'何かお手伝いできることはありますか？Apitorにお問い合わせください！',
		Download:'ダウンロード',
		emailName:'お問い合わせ',
		robot:'私はロボットではありません',
		name:'の名前をあげる',
		Email:'Eメール',
		conter:'メッセージ...',
		Submit:'送信',
		code:'認証コードを入力してください',
		cancel:'キャンセル',
		verify:'検証',
	},
	// {{ $t('about.') }}
	about:{
		text:'お問い合わせ',
		name:'なぜ私たちと協力しているのか',
		title:'お問い合わせ'
	},
	// {{ $t('referral.') }}
	referral:{
		title:'プライバシーポリシー',
		title2:'Apitor使用条件',
		title3:'Apitor法律条項',
	},
	// {{ $t('footer.') }}
	footer:{
		title:'著作権©2022アピターテクノロジー株式会社。無断複写・転載を禁止。',
		title2:'プライバシーポリシー',
		title3:'利用規約',
		title4:'法的規約',
		Follow:'フォローしてください',
		name:'商品情報',
		name2:'アプリ',
		name3:'サポート',
		name4:'Apitorとは',
		name5:'ホームページ',
		text:'App Store',
		text2:'Google Play',
		text3:'組み立て説明書',
		text4:'マニュアル',
		text5:'EC適合性宣言',
		text6:'お問い合わせ',
		nav:'English',
		nav2:'日本語',
	},
	// {{ $t('detail.') }}
	detail:{
		Ages:'推奨年齢',
		Pieces:'ブロック数',
		Model:'モデル',
		ControlModule:'コントロールモジュール',
		Motor:'モーター',
		Senors:'センサー',
		lights:'LEDライト',
		cm:'センチメートル',
		Download:'アプリケーションのダウンロード',
	},
	// {{ $t('contact.') }}
	contact:{
		name:'ありがとうございます！メッセージは送信されました！できるだけ早く返信いたします',
		text:'認証コード入力エラー',
		text1:'不正な電子メールアドレス',
		text2:'名前を空にすることはできません',
		text3:'電子メールアドレスを空にすることはできません',
		text4:'情報を空にすることはできません'
	}
}