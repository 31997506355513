import Vue from 'vue'
import App from './App.vue'
import router from './router'
import routerYD from './router/indexYD'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vant from 'vant';
import 'vant/lib/index.css'
import ElementUI from 'element-ui'; // 2.1引入结构
import 'element-ui/lib/theme-chalk/index.css'; // 2.2引入样式
import store from './store'
import less from 'less'
import VueVideoPlayer from 'vue-video-player'
import i18n from './lang'
import '@vant/touch-emulator';
// 引入样式
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
Vue.use(i18n)
// 使用组件
Vue.use(VueVideoPlayer)

Vue.use(less)
Vue.use(Vant);
Vue.use(VueAxios, axios)
Vue.use(ElementUI);
Vue.config.productionTip = false

// const ISMOBILE = function () {
// 	const flag = navigator.userAgent.match(
// 		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
// 	);
// 	return flag;
// }
const clientWidth = document.documentElement.clientWidth
window.onload = function () {
	// localStorage.setItem('domainName', window.location.hostname)
	// localStorage.setItem('language2', (navigator.language || navigator.browserLanguage).toLowerCase())
	console.log(clientWidth);
	if(clientWidth<751){
		getRem(750, 100)
	}else {
		getRem(1920, 100)
	}
};
window.onresize = function () {
	if(clientWidth<751){
		getRem(750, 100)
	}else {
		getRem(1920, 100)
	}
};
new Vue({
  render: h => h(App),
  router: clientWidth<751 ? routerYD : router,
  store,
  i18n,
}).$mount('#app')
function getRem(pwidth, prem) {
	var html = document.getElementsByTagName("html")[0];
	var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
	html.style.fontSize = oWidth / pwidth * prem + "px";
}